<template>
  <a
    v-if="type == 'more'"
    class="pop-btn"
    :class="[btnClass]"
    href="javascript:;"
    @click="clickEvent"
  >
    <slot />
    <ul v-if="childList" class="more-content">
      <li v-for="(item, index) in childList" :key="'more_btn_' + '_' + index">
        <template v-if="item.url">
          <a
            :href="item.url"
            :disabled="item.disabled"
            :title="item.title"
            :class="item.class"
          >{{ item.name }}</a>
        </template>
        <template v-else>
          <a
            v-if="item.disabled"
            href="javascript:;"
            :disabled="item.disabled"
            :title="item.title"
            :class="item.class"
          >{{ item.name }}</a>
          <a
            v-else
            href="javascript:;"
            :disabled="item.disabled"
            :title="item.title"
            :class="item.class"
            @click="clickChild(item)"
          >{{ item.name }}</a>
        </template>
      </li>
    </ul>
  </a>
  <a v-else class="pop-btn" :class="[btnClass]" @click="clickEvent">
    <slot />
  </a>
</template>

<script type="text/ecmascript-6">
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    childList: {
      type: Array,
      default () {
        return [];
      }
    }
  },
  computed: {
    btnClass () {
      return this.type ? `btn-${this.type}` : '';
    }
  },
  methods: {
    clickEvent () {
      this.$emit('click');
    }
  }
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.pop-btn + .pop-btn
    margin-left 10px
.pop-btn
    display inline-block
    position relative
    padding 0 20px
    height 32px
    line-height 32px
    border-radius 4px
    font-size 14px
    text-align center
    color #4d5063
    vertical-align middle
    overflow hidden
    cursor pointer
    &.btn-default
        min-width 100px
        background #FFFFFF
        border 1px solid #d3d6de
    &.btn-default:hover
        color #1877f2
        border-color #1877f2
    &.btn-primary
        min-width 100px
        background-color #1877f2
        color #fff
        font-weight normal !important
        border-radius 4px
    &.btn-primary:hover
        color #fff
        background-color #6199F4
    &.btn-upload
        min-width 100px
        border-color #1877F2
        color #1877F2
        font-weight normal !important
        cursor pointer
    &.btn-reset
        border 1px solid rgba(45,132,245,1)
        border-radius 4px
    &.btn-danger
        background-color #dc2121
        color #fff
    &.btn-link
        color #279EF4
    &.btn-large
        height 40px
        line-height 40px
        padding 0 20px
        font-size 14px
    &.cancel-btn,&.submit-btn
        display inline-block
    &.modal-btn
        font-size 14px
    &.btn-more
        line-height 40px
        height 40px
        padding 0
        position relative
        overflow visible
        &:hover
            color #A1B9F1
            &::after
                transform scale(0.5) rotate(180deg)
            .more-content
                display block
        &:active
            opacity 1
            filter opacity 100
        &::after
            display inline-block
            content ''
            position relative
            margin-top -2px
            margin-left -5px
            vertical-align middle
            width 28px
            height 28px
            transform scale(0.5) rotate(0deg)
            transition transform 0.3s ease-in-out
            background url('./drop-down.png')
        .more-content
            position absolute
            display none
            border 1px solid rgba(0, 0, 0, 0.08)
            box-shadow 0 4px 12px 0 rgba(0, 0, 0, 0.2)
            z-index 99
            line-height 22px
            a
                display block
                padding 5px 10px
                background #fff
                white-space nowrap
                color #666666
                text-align left
                &:hover
                    color #999999
                &[disabled]
                    cursor not-allowed
                    pointer-events auto
                    color #ccc !important
                    &:hover
                        color #ccc !important
                    &:active
                        color #ccc !important
</style>
